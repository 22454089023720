import { Facebook, Instagram } from "../components/Icons"
import i18n from "../i18n"

export const getFooterData = () => ({
  [`Rod's`]: [
    { name: "Présentation", href: "#" },
    { name: "Ecole", href: "#" },
    { name: "L'atelier", href: "#" },
    { name: "Le magasin", href: "#" },
    { name: "Le restaurant", href: "#" },
  ],
  workshop: [
    { name: i18n.t("footer.workshopSection.board"), href: "#" },
    { name: i18n.t("footer.workshopSection.customMade"), href: "#" },
    { name: i18n.t("footer.workshopSection.boardModel"), href: "#" },
    { name: i18n.t("footer.workshopSection.repair"), href: "#" },
  ],
  school: [
    { name: i18n.t("footer.schoolSection.school"), href: "#" },
    { name: i18n.t("footer.schoolSection.team"), href: "#" },
    { name: i18n.t("footer.schoolSection.lessons"), href: "#" },
    { name: i18n.t("footer.schoolSection.prices"), href: "#" },
    { name: i18n.t("footer.schoolSection.book"), href: "#" },
  ],
  shop: [
    { name: i18n.t("footer.shopSection.rental"), href: "#" },
    { name: i18n.t("footer.shopSection.board"), href: "#" },
    { name: i18n.t("footer.shopSection.gear"), href: "#" },
    { name: i18n.t("footer.shopSection.skate"), href: "#" },
    { name: i18n.t("footer.shopSection.clothes"), href: "#" },
  ],
  compagny: [
    { name: i18n.t("footer.compagnySection.aboutUs"), href: "#" },
    { name: i18n.t("footer.compagnySection.recruitment"), href: "#" },
    { name: i18n.t("footer.compagnySection.partner"), href: "#" },
  ],
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/Rods-Surfboards-91415147266/",
      icon: Facebook,
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/rodssurfboard/",
      icon: Instagram,
    },
  ],
})
