import React, { useState, useEffect } from "react"
import { ChevronDownIcon } from "@heroicons/react/outline"

import { classNames, getCurrentCountry } from "../utilities"
import i18n from "../i18n"
import { navigate } from "gatsby-link"

const flags = {
  fr: { text: "🇫🇷   FR", value: "fr" },
  en: { text: "🇬🇧   EN", value: "en" },
  de: { text: "🇩🇪   DE", value: "de" },
  // es: { text: "🇪🇸   ES", value: "es" },
  // nl: { text: "🇳🇱   NL", value: "nl" },
}

type Props = { small: boolean; animated?: boolean }

//TODO: handle dynamicly flag based on browser country
const CountryPicker: React.FunctionComponent<Props> = ({ small, animated }) => {
  const currentCountry = getCurrentCountry()
  const [flag, setFlag] = useState(currentCountry)

  useEffect(() => {
    if (currentCountry !== flag) {
      i18n.locale = flag
      navigate(`/${flag}/`)
    }
  }, [flag])

  const options = Object.entries(flags).map((entry) => entry[1])
  return (
    <form>
      <div>
        <label htmlFor="desktop-country-picker" className="sr-only">
          {i18n.t("flag")}
        </label>
        <div className="-ml-2 group relative bg-white border-transparent rounded-md focus-within:ring-2 focus-within:ring-blue-500 ">
          <select
            id={`desktop-country-picker-select-${animated ? 2 : 1}`}
            name="currency"
            className={classNames(
              small ? "pr-5" : "pr-5",
              "appearance-none border-transparent bg-none bg-white  rounded-md py-0.5 pl-2 flex items-center text-sm font-medium text-gray-500 group-hover:text-gray-900 focus:outline-none focus:ring-0 focus:border-transparent"
            )}
            onChange={(e) => setFlag(e.target.value)}
            value={flag}
          >
            {options.map((flag) => (
              <option key={flag.text} value={flag.value}>
                {flag.text}
              </option>
            ))}
          </select>
          <div className="absolute right-0 inset-y-0 flex items-center pointer-events-none">
            <ChevronDownIcon
              className={"text-gray-600 ml-2 h-5 w-5 group-hover:text-gray-500"}
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
    </form>
  )
}

export default CountryPicker
