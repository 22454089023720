import { ImageQuery } from "./types"

export const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ")
}

export const getCurrentCountry = () => {
  const pathname =
    typeof window !== "undefined" ? window?.location?.pathname : "/fr/"

  if (isFrance(pathname)) {
    return `fr`
  }
  if (isEnglish(pathname)) {
    return `en`
  }
  if (isGerman(pathname)) {
    return `de`
  }
  if (isSpanish(pathname)) {
    return `es`
  }
  if (isDutchman(pathname)) {
    return `nl`
  }

  return `fr`
}

const isFrance = (pathName: string) => !!pathName.match("/fr/")
const isEnglish = (pathName: string) => !!pathName.match("/en/")
const isGerman = (pathName: string) => !!pathName.match("/de/")
const isSpanish = (pathName: string) => !!pathName.match("/es/")
const isDutchman = (pathName: string) => !!pathName.match("/nl/")

export const getImage = (data: ImageQuery) => {
  const imageNode = data?.image?.edges?.[0].node
  const imageName = imageNode?.name
  const image = imageNode?.childImageSharp?.gatsbyImageData
  const hasImage = imageName && image

  return {
    imageName,
    imageId: imageNode?.id,
    image,
    hasImage,
  }
}

export const getData = (data: any) => {
  const {
    shop_title,
    shop_subsection,
    section,
    review,
    review_name,
    review_picture,
    shop_description,
    restaurant_title,
    restaurant_description,
    restaurant_picture,
    restaurant_text,
    table_prices,
    picture_under_table_prices,
    rental_per_day,
    rental_per_hour,
    contact_section,
    subscribe_button,
    shape_price_option_title,
    shape_price_title,
    shape_price_image,
    shape_price,
    shape_options,
  } = data.prismicHomepage.data
  const shape = section[0]
  const school = section[1]
  const rent = section[2]
  return {
    shape,
    school,
    rent,
    review: { review_picture, review, review_name },
    shop: { shop_description, shop_title, shop_subsection },
    restaurant: {
      restaurant_title,
      restaurant_description,
      restaurant_picture,
      restaurant_text,
    },
    tablePrice: table_prices,
    familyPicture: picture_under_table_prices,
    rental: {
      rental_per_day,
      rental_per_hour,
    },
    contact: contact_section,
    subscribeButton: subscribe_button.text,
    shapePriceOptionTitle: shape_price_option_title.text,
    shapePriceTitle: shape_price_title.text,
    shapePriceImage: shape_price_image,
    shapePrice: shape_price,
    shapeOptions: shape_options,
  }
}
