import React from "react"
import { Link } from "gatsby"

import { Logo } from "./Icons"
import i18n from "../i18n"
import CountryPicker from "./CountryPicker"
import { classNames } from "../utilities"

type HeaderProps = {
  className?: string
  countrypicker?: boolean
  animated?: boolean
}

const HeaderWithoutNavigation = ({ className, animated }: HeaderProps) => {
  return (
    <header className={className}>
      <div className="relative bg-white shadow-md">
        <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-2 sm:px-2">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <span className="sr-only">{i18n.t(`header.sr.menu`)}</span>
            <div className="h-8  sm:h-10 flex justify-center items-center">
              <Link to="/">
                <span className="sr-only">{i18n.t(`header.sr.logo`)}</span>
                <Logo className="h-8 w-auto sm:h-10" />
              </Link>
            </div>
          </div>
          <div className="pr-2 ">
            <CountryPicker animated={animated} small />
          </div>
        </div>
      </div>
    </header>
  )
}

export const AnimatedHeader = ({ inView = false }) => (
  <HeaderWithoutNavigation
    animated
    className={classNames(
      inView ? "opacity-0 hidden" : "opacity-1",
      "sticky top-0 z-50 transition-opacity duration-300"
    )}
  />
)

export default HeaderWithoutNavigation
