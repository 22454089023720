import React, { FC } from "react"

type Props = any

const Logo: FC<Props> = ({ props }) => {
  return (
    <span
      className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
      {...props}
    >
      {/* {`<logo> `} */}
      <span className="font-bold tracking-wider bg-clip-text text-transparent bg-gradient-to-r from-blue-700 via-blue-500 to-teal-400">
        Rod's Surfboard
      </span>
    </span>
  )
}

export default Logo
