import React, { FC } from "react"
import Footer from "../Footer"
import HeaderWithoutNavigation from "../HeaderWithoutNavigation"
import { Helmet } from "react-helmet"

type Lang = "fr" | "en" | "de"

const getMetaDescription = (lang: Lang) => {
  switch (lang) {
    case "fr":
      return "L’équipe Rods Surfboard vous propose des cours de surf et met à votre disposition le matériel de surf nécessaire pour profiter du spot de Carcans plage"
    case "de":
      return "Das Team von Rods Surfboard bietet Ihnen Surfkurse an und stellt Ihnen die nötige Surfausrüstung zur Verfügung, damit Sie den Spot von Carcans plage nutzen können."
    case "en":
    default:
      return "The Rods Surfboard team offers you surfing lessons and provides you with the necessary surfing equipment to enjoy the spot of Carcans plage"
  }
}

const Layout: FC<{ lang?: Lang }> = ({ children, lang = "fr" }) => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
      >
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
        <title>Rods Surfboard</title>
        <meta name="description" content={getMetaDescription(lang)} />
      </Helmet>
      <HeaderWithoutNavigation />
      <div className="bg-white">
        <main className="min-h-3/4">{children}</main>
        <Footer />
      </div>
    </>
  )
}

export default Layout
