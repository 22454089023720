import React from "react"

import { getFooterData } from "../data/footer"
import i18n from "../i18n"
// import SubscribeNewsletter from "./Layout/Footer/SubscribeNewsletter"

const Footer = () => {
  const FOOTER_NAVIGATION = getFooterData()
  return (
    <footer className="bg-gray-50" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        {i18n.t("footer.sr.heading")}
      </h2>
      <div className="max-w-7xl mx-auto pt-12 pb-8 px-4 sm:px-6 lg:pt-12 lg:px-8">
        {/* <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  {i18n.t("footer.workshop")}
                </h3>
                <ul className="mt-4 space-y-4">
                  {FOOTER_NAVIGATION.workshop.map((item) => (
                    <li key={item.name}>
                      <p
                        // href={item.href}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  {i18n.t("footer.school")}
                </h3>
                <ul className="mt-4 space-y-4">
                  {FOOTER_NAVIGATION.school.map((item) => (
                    <li key={item.name}>
                      <p
                        // // href={item.href}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  {i18n.t("footer.shop")}
                </h3>
                <ul className="mt-4 space-y-4">
                  {FOOTER_NAVIGATION.shop.map((item) => (
                    <li key={item.name}>
                      <p
                        // // href={item.href}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  {i18n.t("footer.compagny")}
                </h3>
                <ul className="mt-4 space-y-4">
                  {FOOTER_NAVIGATION.compagny.map((item) => (
                    <li key={item.name}>
                      <p
                        // // href={item.href}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <SubscribeNewsletter />
        </div> */}

        <div className="border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            {FOOTER_NAVIGATION.social.map((item) => (
              <a
                key={item.name}
                target="_blank"
                rel="noopener noreferrer"
                href={item.href}
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <p className="mt-8 text-base text-gray-500 md:mt-0 md:order-1 mb-0">
            &copy;
            {` ${new Date().getFullYear() || ""} Rod's Surfboards, ${i18n.t(
              "footer.rightsReserved"
            )}`}
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
